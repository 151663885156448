import { useMutation } from '@tanstack/react-query';

export const useUploadImage = () => {
  return useMutation({
    mutationFn: ({ file, url }: { url: string; file: File }) =>
      fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
      }),
  });
};
