import { useUserData } from '@conteg/auth';
import {
  Alert,
  Breadcrumb,
  Breadcrumbs,
  Flex,
  Loading,
  showAlertModalError,
} from '@conteg/ui';
import { ContentLoader } from 'components/content-loader/content-loader';
import { PageHeading } from 'components/page-heading/page-heading';
import { usePolicyEvaluate } from 'components/policy-loader/policy-loader';
import { useInvalidateQueries } from 'hooks/use-invalidate-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Language,
  ProjectDocument,
  useProjectQuery,
  useUpdateProjectMutation,
} from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';
import { substituteRouteParams } from 'utils/routing/helpers';
import { CompanyDetailTabs, RouteUrls } from 'utils/routing/router';

import ProjectForm, { ProjectFormInputs } from './components/project-form';

const ProjectDetail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { projectId, subjectId } = useParams<{
    projectId: string;
    subjectId: string;
  }>();

  const { showSuccess } = useAlertContext();
  const invalidate = useInvalidateQueries();
  const user = useUserData();

  const canEditProject =
    usePolicyEvaluate('canEditProject', 'organization') || user?.isProvider;

  const { mutate: updateProject } = useUpdateProjectMutation();

  const { data: project, isLoading: isProjectLoading } = useProjectQuery(
    {
      id: projectId ?? '',
    },
    {
      enabled: !!projectId,
      select: (data) => data.project,
    }
  );

  const handleSubmit = (formData: ProjectFormInputs) => {
    const { name, companyContact, defaultLanguage, fallbackLanguage } =
      formData;

    updateProject(
      {
        project: {
          id: projectId ?? '',
          name,
          companyContact,
          defaultLanguage: defaultLanguage as Language,
          fallbackLanguage: fallbackLanguage as Language,
        },
      },
      {
        onSuccess: () => {
          showSuccess(t('ProjectUpdateForm.Form.Submit.Success'));
          invalidate([ProjectDocument]);
        },
        onError: (err) =>
          showAlertModalError(t('ProjectUpdateForm.Form.Submit.Error'), err),
      }
    );
  };

  if (!projectId)
    return (
      <Alert
        type="error"
        message={t('Error.MissingUrlParam', { value: 'projectId' })}
      />
    );

  if (isProjectLoading) return <Loading />;

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" gap="6.25rem">
        <Flex flexDirection="column" gap="9rem">
          <Breadcrumbs
            items={[
              {
                canGoBack: true,
                label: t('Breadcrumb.Home'),
                url: RouteUrls.Companies,
              },
              {
                canGoBack: true,
                label: t('Breadcrumb.DetailCompany'),
                url: substituteRouteParams(
                  `${RouteUrls.CompanyDetail}${CompanyDetailTabs.Projects}`,
                  {
                    subjectId: subjectId ?? '',
                  }
                ),
              },
              {
                canGoBack: true,
                label: t('Breadcrumb.DetailProject'),
                url: substituteRouteParams(RouteUrls.ProjectDetail, {
                  projectId,
                  subjectId,
                }),
              },
            ]}
            navigate={(to: Breadcrumb) => navigate(to.url)}
          />
          {isProjectLoading ? (
            <ContentLoader />
          ) : (
            <PageHeading title={project?.name ?? t('DetailProject.Header')} />
          )}
          <ProjectForm
            defaultValues={{
              companyContact: project?.companyContact,
              name: project?.name,
              defaultLanguage: project?.defaultLanguage,
              fallbackLanguage: project?.fallbackLanguage,
            }}
            canEdit={canEditProject}
            title={t('ProjectUpdateForm.Form.Header')}
            onSubmit={handleSubmit}
            submitButtonLabel={t('ProjectUpdateForm.Form.SubmitButton')}
            onGoBack={() =>
              navigate(
                substituteRouteParams(RouteUrls.CompanyDetail, { subjectId })
              )
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProjectDetail;
