import { ConfirmModal, showAlertModalError } from '@conteg/ui';
import { useInvalidateQueries } from 'hooks/use-invalidate-query';
import { useTranslation } from 'react-i18next';
import {
  useDeleteUserFromRoleMutation,
  UserDocument,
  UserRoleFragment,
} from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';

type DeleteUserRoleModalProps = {
  role?: UserRoleFragment | null;
  userId: string;
  onClose: () => void;
};
export const DeleteUserRoleModal = ({
  role,
  userId,
  onClose,
}: DeleteUserRoleModalProps) => {
  const { t } = useTranslation();
  const { showSuccess } = useAlertContext();
  const invalidate = useInvalidateQueries();

  const { mutate: deleteUserFromRole, isPending } =
    useDeleteUserFromRoleMutation({
      onSuccess: () => {
        showSuccess(t('UserUpdateForm.RemoveUserRole.SuccessMessage'));
        invalidate([UserDocument]);
        onClose();
      },
      onError: (err) => {
        showAlertModalError(
          t('UserUpdateForm.RemoveUserRole.ErrorMessage'),
          err
        );
      },
    });

  return (
    <ConfirmModal
      isLoading={isPending}
      modalTitle={t('UserUpdateForm.RemoveUserRole.Title', {
        roleName: t(`RolesDocumentation.Role.${role?.name}`),
      })}
      isOpen={!!role}
      onClose={onClose}
      onConfirm={() => {
        if (role) {
          deleteUserFromRole({ roleId: role.id, userId });
        }
      }}
      cancelText={t('Cancel')}
      confirm={{
        variant: 'primary',
        text: t('Yes'),
      }}
    />
  );
};
