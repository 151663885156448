import {
  Alert,
  Box,
  Flex,
  Heading,
  Loading,
  showAlertModalError,
  styled,
  Text,
} from '@conteg/ui';
import { useInvalidateQueries } from 'hooks/use-invalidate-query';
import { FileUploadInput } from 'pages/companies/company/components/company-logo/file-upload-button';
import { ImageContainer } from 'pages/companies/company/components/company-logo/image-container';
import { useUploadImage } from 'pages/companies/company/components/company-logo/use-upload-image';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CompanyDocument,
  useLogoUploadLinkQuery,
  useVerifyUploadedLogoMutation,
} from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';

type CompanyLogoProps = {
  companyId: string;
  imageUrl?: string | null;
  companyTitle: string;
  shouldEnableEdit: boolean;
};

const MAX_FILE_SIZE_IN_MB = 1;

const SUPPORTED_FILE_EXTENSION = '.svg';

export const CompanyLogo = ({
  shouldEnableEdit,
  companyId,
  imageUrl,
  companyTitle,
}: CompanyLogoProps) => {
  const [file, setFile] = useState<File | null>(null);

  const { showSuccess } = useAlertContext();
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const invalidate = useInvalidateQueries();

  const { mutate: verifyUploadedImage, isPending: isVerifyingImage } =
    useVerifyUploadedLogoMutation({
      onSuccess: () => {
        invalidate([CompanyDocument]);
        showSuccess(t('CompanyLogo.Image.SuccessMessage'));
      },
    });

  const { mutateAsync: uploadImage, isPending: isUploadingImage } =
    useUploadImage();

  const { data, isLoading: isLoadingPointUrl } = useLogoUploadLinkQuery(
    {
      companyId,
      fileExtension: SUPPORTED_FILE_EXTENSION,
    },
    { enabled: !!file }
  );

  useEffect(() => {
    if (data?.logoUploadLink && file) {
      (async () => {
        await uploadImage(
          {
            url: data?.logoUploadLink.uploadImageUrl,
            file,
          },
          {
            onError: (error) => {
              setFile(null);
              showAlertModalError(t('CompanyLogo.Image.ErrorMessage'), error);
            },
            onSuccess: () => {
              setFile(null);
            },
          }
        );

        verifyUploadedImage({
          companyId,
          uploadedBlobName: data.logoUploadLink.uploadedBlobName,
        });
      })();
    }
    // We don't need more dependencies here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.logoUploadLink]);

  const isLoading =
    isVerifyingImage || isUploadingImage || (isLoadingPointUrl && !!file);

  const handleFileChange = (file: File | null) => {
    if (!file) {
      return;
    }
    const sizeInMB = file?.size / 1024 / 1024;
    const uploadFileExtension = file ? `.${file?.name.split('.')[1]}` : '';

    if (uploadFileExtension !== SUPPORTED_FILE_EXTENSION) {
      setErrorMessage(t('CompanyLogo.Image.WrongFormatErrorMessage') as string);
      return;
    }
    if (sizeInMB > MAX_FILE_SIZE_IN_MB) {
      return setErrorMessage(
        t('CompanyLogo.Image.FileIsToBig', {
          maxSize: MAX_FILE_SIZE_IN_MB,
        }) as string
      );
    }
    setFile(file);
  };

  return (
    <Container>
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="3rem"
      >
        <Heading
          title={t('CompanyLogo.Image.SuccessMessage.Image.Title')}
          variant="h3"
        />
        {shouldEnableEdit && (
          <FileUploadInput
            onChange={handleFileChange}
            maxSizeInMb={0}
            hasImage={!!imageUrl}
          />
        )}
      </Flex>

      {errorMessage && (
        <Box height="12rem">
          <Alert type="error" message={errorMessage} />
        </Box>
      )}
      <Flex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        gap="5rem"
        width="100%"
      >
        {isLoading ? (
          <Box height="50rem">
            <Loading />
          </Box>
        ) : (
          <>
            <Text content={t('CompanyLogo.Image.InfoMessage')} />
            <ImageContainer imageUrl={imageUrl} title={companyTitle} />
          </>
        )}
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  padding: 4rem;
  width: 100%;
  min-height: 70rem;
  border-radius: 3rem;
  background-color: rgba(12, 22, 140, 0.05);
`;
