import {
  Breadcrumb,
  Breadcrumbs,
  Flex,
  Loading,
  showAlertModalError,
} from '@conteg/ui';
import { PageHeading } from 'components/page-heading/page-heading';
import { useInvalidateQueries } from 'hooks/use-invalidate-query';
import ProjectForm, {
  ProjectFormInputs,
} from 'pages/projects/project/components/project-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Language,
  ProjectsDocument,
  useCreateProjectMutation,
} from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';
import { substituteRouteParams } from 'utils/routing/helpers';
import { RouteUrls } from 'utils/routing/router';

const CreateProject = () => {
  const { t } = useTranslation();
  const invalidate = useInvalidateQueries();
  const { subjectId } = useParams<{
    subjectId: string;
  }>();

  const { mutateAsync: createProject, isPending } = useCreateProjectMutation({
    onSuccess: (data) => {
      showSuccess(t('ProjectForm.Form.Create.Success'));
      invalidate([ProjectsDocument]);
      navigate(
        substituteRouteParams(RouteUrls.ProjectDetail, {
          subjectId,
          projectId: data.createProject.id,
        })
      );
    },
    onError: (error) => {
      const message = error?.toString().includes('PhoneNumber')
        ? t('ProjectForm.Form.Create.PhoneNumber.Error')
        : t('ProjectForm.Form.Create.Error');

      showAlertModalError(message, error);
    },
  });

  const navigate = useNavigate();
  const { showSuccess } = useAlertContext();

  const handleSubmit = async (formData: ProjectFormInputs) => {
    const { name, companyContact, defaultLanguage, fallbackLanguage } =
      formData;
    await createProject({
      project: {
        ownerSubjectId: subjectId ?? '',
        name,
        companyContact: companyContact ?? '',
        defaultLanguage: defaultLanguage as Language,
        fallbackLanguage: fallbackLanguage as Language,
      },
    });
  };

  if (isPending) return <Loading />;

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" gap="6.25rem">
        <Flex flexDirection="column" gap="9rem">
          <Breadcrumbs
            items={[
              {
                canGoBack: true,
                label: t('Breadcrumb.Home'),
                url: RouteUrls.Companies,
              },
              {
                canGoBack: true,
                label: t('Breadcrumb.DetailCompany'),
                url: substituteRouteParams(RouteUrls.CompanyDetail, {
                  subjectId,
                }),
              },
              {
                canGoBack: true,
                label: t('Breadcrumb.CreateProject'),
                url: substituteRouteParams(RouteUrls.CompanyDetail, {
                  subjectId,
                }),
              },
            ]}
            navigate={(to: Breadcrumb) => navigate(to.url)}
          />
          <PageHeading title={t('ProjectForm.Form.Header')} />
          <ProjectForm
            defaultValues={{
              fallbackLanguage: Language.En,
            }}
            canEdit
            title={t('ProjectForm.Form.Header')}
            onSubmit={handleSubmit}
            submitButtonLabel={t('Projects.Button.AddProject')}
            onGoBack={() =>
              navigate(
                substituteRouteParams(RouteUrls.CompanyDetail, {
                  subjectId,
                })
              )
            }
            hasNewProjectFields
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CreateProject;
