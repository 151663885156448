import { FINISH_REGISTRATION_ROUTE } from 'config';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAcceptInvitationMutation } from 'types/generated/graphql';
import { openExternalLink } from 'utils/open-external-link/open-external-link';
import { substituteRouteParams } from 'utils/routing/helpers';

export const useAcceptInviteMutation = () => {
  const [customError, setCustomError] = useState<string | null>(null);

  const { t } = useTranslation();
  const { mutateAsync, isPending, isError, error } =
    useAcceptInvitationMutation({
      onSuccess: (data, variables) => {
        const token = data.acceptInvitation.identityToken;

        if (token) {
          openExternalLink(
            substituteRouteParams(FINISH_REGISTRATION_ROUTE, {
              token,
              userEmail: variables.userEmail,
            })
          );
        } else {
          setCustomError(t('Invitation.Header.Error.TokenNotFound'));
        }
      },
    });

  const acceptInvitation = useCallback(
    (invitationId: string, userEmail: string) => {
      mutateAsync({ invitationId, userEmail });
    },
    []
  );

  return {
    acceptInvitation,
    isAcceptingInvite: isPending,
    isAcceptInviteError: isError,
    acceptInviteError: error,
    customError,
  };
};
