import 'utils/localization/i18n';

import { useAuth } from '@conteg/auth';
import { Flex, styled } from '@conteg/ui';
import { CUSTOM_AUTH_TOKEN } from 'config';
import AcceptInvitation from 'pages/accept-invitation/accept-invitation';
import Companies from 'pages/companies/companies/companies';
import CompanyDetail from 'pages/companies/company/company-detail';
import CreateCompany from 'pages/companies/create-company/create-company';
import Login from 'pages/login/login';
import CreateProject from 'pages/projects/create-project/create-project';
import ProjectDetail from 'pages/projects/project/project-detail';
import { RolesDocumentation } from 'pages/roles-documentation/roles-documentation';
import UserDetail from 'pages/user-detail/user-detail';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { Navbar } from 'utils/routing/navbar';
import { PublicOnlyRoute } from 'utils/routing/public-only-route';

import Layout from './layout';
import { ProtectedRoute } from './protected-route';

export enum RouteUrls {
  Root = '/',
  Login = '/login',
  AcceptInvitation = '/invitation/redirect/:invitationId/:userEmail/',
  Companies = '/companies',
  CompanyDetail = '/companies/:subjectId/*',
  CreateCompany = '/companies/create',
  UserDetail = '/companies/:subjectId/users/:userId/',
  Projects = '/projects',
  ProjectDetail = '/companies/:subjectId/projects/:projectId',
  CreateProject = '/companies/:subjectId/projects/create',
  RolesDocumentation = '/roles-documentation',
}

export enum CompanyDetailTabs {
  Contact = 'contact',
  Users = 'users',
  Projects = 'projects',
  Invitations = 'invitations',
}

const routes: RouteProps[] = [
  { path: RouteUrls.Root, element: <Login /> },
  {
    path: RouteUrls.Login,
    element: <Login />,
  },
  { path: RouteUrls.AcceptInvitation, element: <AcceptInvitation /> },
];

const authRoutes: RouteProps[] = [
  { path: RouteUrls.RolesDocumentation, element: <RolesDocumentation /> },
  { path: RouteUrls.Companies, element: <Companies /> },
  { path: RouteUrls.CompanyDetail, element: <CompanyDetail /> },

  { path: RouteUrls.CreateCompany, element: <CreateCompany /> },
  {
    path: RouteUrls.UserDetail,
    element: <UserDetail />,
  },
  {
    path: RouteUrls.ProjectDetail,
    element: <ProjectDetail />,
  },
  {
    path: RouteUrls.CreateProject,
    element: <CreateProject />,
  },
  { path: '*', element: <p>There is nothing here: 404!</p> },
];

const FlexWrapper = styled(Flex)`
  height: 100vh;
`;

const pathName = window.location.pathname;

const isPathNameLogin = [RouteUrls.Login, RouteUrls.Root].includes(
  pathName as RouteUrls
);

const Router: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <FlexWrapper flexDirection="column">
      {(isAuthenticated || CUSTOM_AUTH_TOKEN) && <Navbar />}
      <Routes>
        {routes.map((route) => (
          <Route
            path={route.path}
            key={route.path}
            element={
              <PublicOnlyRoute
                redirectPath={isPathNameLogin ? RouteUrls.Companies : pathName}
                authenticated={isAuthenticated}
              >
                {route.element}
              </PublicOnlyRoute>
            }
          />
        ))}
        <Route element={<Layout />}>
          {authRoutes.map((route) => (
            <Route
              path={route.path}
              key={route.path}
              element={
                <ProtectedRoute
                  authenticated={isAuthenticated}
                  path={`${pathName}${window.location.search}`}
                >
                  {route.element}
                </ProtectedRoute>
              }
            />
          ))}
        </Route>
      </Routes>
    </FlexWrapper>
  );
};

export default Router;
