import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from 'utils/gql/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: string; output: string; }
  UUID: { input: string; output: string; }
};

export type AcceptInvitationDtoInput = {
  invitationId: Scalars['UUID']['input'];
  userEmail: Scalars['String']['input'];
};

export type AcceptedInvitationDto = {
  __typename?: 'AcceptedInvitationDto';
  identityToken: Scalars['String']['output'];
};

export enum AccessCredentialAction {
  Peek = 'PEEK',
  Pickup = 'PICKUP',
  StockIn = 'STOCK_IN',
  StockOut = 'STOCK_OUT',
  Store = 'STORE'
}

export type AccessCredentialsRuleDto = {
  __typename?: 'AccessCredentialsRuleDto';
  accessCredentialActions: Array<AccessCredentialAction>;
  charactersRange?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  integrationProfileId: Scalars['UUID']['output'];
  pinCodeApplicability?: Maybe<PinCodeApplicability>;
  pinCodeLength?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['UUID']['output']>;
  requireInstallationId: Scalars['Boolean']['output'];
  requirePersonalIdentifier: Scalars['Boolean']['output'];
  requirePhoneNumber: Scalars['Boolean']['output'];
  requirePinCode: Scalars['Boolean']['output'];
  requirePointId: Scalars['Boolean']['output'];
  requireToSpecifyValidFrom: Scalars['Boolean']['output'];
  requireToSpecifyValidTo: Scalars['Boolean']['output'];
  tenantSubjectId: Scalars['UUID']['output'];
  uniqueCharacters?: Maybe<Scalars['String']['output']>;
  uniquenessKind: UniquenessKind;
};

export type AddressDto = {
  __typename?: 'AddressDto';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  street: Scalars['String']['output'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type CompanyDto = {
  __typename?: 'CompanyDto';
  address: AddressDto;
  contactPersonId: Scalars['UUID']['output'];
  dic?: Maybe<Scalars['String']['output']>;
  ico?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isProvider: Scalars['Boolean']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  logoUrlUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  projects: Array<CompanyProjectDto>;
  roles: Array<RoleDto>;
  subjectId: Scalars['UUID']['output'];
  type: CompanyType;
  users: UserDtoPaginatedListDto;
};


export type CompanyDtoUsersArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type CompanyLogoUploadLinkDto = {
  __typename?: 'CompanyLogoUploadLinkDto';
  uploadImageUrl: Scalars['String']['output'];
  uploadedBlobName: Scalars['String']['output'];
};

export type CompanyProjectDto = {
  __typename?: 'CompanyProjectDto';
  isOwner: Scalars['Boolean']['output'];
  isProvider: Scalars['Boolean']['output'];
  isTenant: Scalars['Boolean']['output'];
  projectId: Scalars['UUID']['output'];
  projectName?: Maybe<Scalars['String']['output']>;
};

export type CompanyTableItemDto = {
  __typename?: 'CompanyTableItemDto';
  id: Scalars['UUID']['output'];
  isProvider: Scalars['Boolean']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  logoUrlUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  subjectId: Scalars['UUID']['output'];
  teamsMembersCount: Scalars['Int']['output'];
  type: CompanyType;
};

export type CompanyTableItemDtoPaginatedListDto = {
  __typename?: 'CompanyTableItemDtoPaginatedListDto';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  items: Array<CompanyTableItemDto>;
  pageIndex: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export enum CompanyType {
  Big = 'BIG',
  Conteg = 'CONTEG',
  DevelopmentAndTesting = 'DEVELOPMENT_AND_TESTING',
  Small = 'SMALL'
}

export type CreateAccessCredentialsRuleDtoInput = {
  accessCredentialActions: Array<AccessCredentialAction>;
  charactersRange?: InputMaybe<Scalars['String']['input']>;
  integrationProfileId: Scalars['UUID']['input'];
  pinCodeApplicability?: InputMaybe<PinCodeApplicability>;
  pinCodeLength?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['UUID']['input']>;
  requireInstallationId: Scalars['Boolean']['input'];
  requirePersonalIdentifier: Scalars['Boolean']['input'];
  requirePhoneNumber: Scalars['Boolean']['input'];
  requirePinCode: Scalars['Boolean']['input'];
  requirePointId: Scalars['Boolean']['input'];
  requireToSpecifyValidFrom: Scalars['Boolean']['input'];
  requireToSpecifyValidTo: Scalars['Boolean']['input'];
  tenantSubjectId: Scalars['UUID']['input'];
  uniqueCharacters?: InputMaybe<Scalars['String']['input']>;
  uniquenessKind: UniquenessKind;
};

export type CreateCompanyDtoInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  dic?: InputMaybe<Scalars['String']['input']>;
  ico?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  street: Scalars['String']['input'];
  type: CompanyType;
};

export type CreateIntegrationProfileDtoInput = {
  handlingCustomersNotifyingOnBehalfOfPartner: Scalars['Boolean']['input'];
  isPaymentFlowEnabled: Scalars['Boolean']['input'];
  maxPeriod: Scalars['String']['input'];
  maxUnanchoredBookingPercentageRatio: Scalars['Int']['input'];
  paymentAppName?: InputMaybe<Scalars['String']['input']>;
  paymentUrl?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['UUID']['input'];
  requireWhitelistVerification: Scalars['Boolean']['input'];
  serviceType: ServiceType;
  tenantSubjectId: Scalars['UUID']['input'];
  verifySenderPhoneNumber: Scalars['Boolean']['input'];
  webHookUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInvitationDtoInput = {
  companyId: Scalars['UUID']['input'];
  userEmail: Scalars['String']['input'];
};

export type CreateProjectDtoInput = {
  companyContact: Scalars['String']['input'];
  defaultLanguage: Language;
  fallbackLanguage: Language;
  jiraIssueTypeKey?: InputMaybe<Scalars['String']['input']>;
  jiraProjectKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  ownerSubjectId: Scalars['UUID']['input'];
};

export type IntegrationProfileDto = {
  __typename?: 'IntegrationProfileDto';
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  canManageNotifications: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  handlingCustomersNotifyingOnBehalfOfPartner: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  isPaymentFlowEnabled: Scalars['Boolean']['output'];
  lastChangeAt: Scalars['DateTime']['output'];
  maxPeriod: Scalars['String']['output'];
  maxUnanchoredBookingPercentageRatio: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  paymentAppName?: Maybe<Scalars['String']['output']>;
  paymentUrl?: Maybe<Scalars['String']['output']>;
  projectId: Scalars['UUID']['output'];
  projectName?: Maybe<Scalars['String']['output']>;
  requireWhitelistVerification: Scalars['Boolean']['output'];
  serviceAccountUserId: Scalars['UUID']['output'];
  serviceAccountUserPassword?: Maybe<Scalars['String']['output']>;
  serviceType: ServiceType;
  tenantName?: Maybe<Scalars['String']['output']>;
  tenantSubjectId: Scalars['UUID']['output'];
  tierId: Scalars['UUID']['output'];
  verifySenderPhoneNumber: Scalars['Boolean']['output'];
  webHookUrl?: Maybe<Scalars['String']['output']>;
  webhookApiVersion: WebhookApiVersion;
  webhookReservationStatusChanged: Scalars['Boolean']['output'];
};

export type InvitationDto = {
  __typename?: 'InvitationDto';
  id: Scalars['UUID']['output'];
};

export enum InvitationStatus {
  Finished = 'FINISHED',
  InProgress = 'IN_PROGRESS',
  NotAccepted = 'NOT_ACCEPTED'
}

export type InvitationStatusDto = {
  __typename?: 'InvitationStatusDto';
  id: Scalars['UUID']['output'];
  status: InvitationStatus;
  verificationEmailText?: Maybe<Scalars['String']['output']>;
};

export type InvitationTableItemDto = {
  __typename?: 'InvitationTableItemDto';
  expiration: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  isAccepted: Scalars['Boolean']['output'];
  userEmail: Scalars['String']['output'];
};

export type InvitationTableItemDtoPaginatedListDto = {
  __typename?: 'InvitationTableItemDtoPaginatedListDto';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  items: Array<InvitationTableItemDto>;
  pageIndex: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export enum Language {
  Cs = 'CS',
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  Sk = 'SK',
  Uk = 'UK'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation: AcceptedInvitationDto;
  addUserToRole: Scalars['ID']['output'];
  createAccessCredentialsRule: Scalars['Boolean']['output'];
  createCompany: CompanyDto;
  createIntegrationProfile: IntegrationProfileDto;
  createInvitation: InvitationDto;
  createProject: ProjectDto;
  deleteAccessCredentialsRule: Scalars['Boolean']['output'];
  deleteInvitation: Scalars['Boolean']['output'];
  deleteUserFromRole: Scalars['ID']['output'];
  generateNewIntegrationProfilePassword: Scalars['String']['output'];
  generateWebhookCredentials: WebhookCredentialDto;
  setIntegrationProfileState: Scalars['Boolean']['output'];
  updateAccessCredentialsRule: Scalars['Boolean']['output'];
  updateCompany: CompanyDto;
  updateIntegrationProfile: IntegrationProfileDto;
  updateProject: ProjectDto;
  updateUser: UserDto;
  verifyUploadedLogo: Scalars['Boolean']['output'];
};


export type MutationAcceptInvitationArgs = {
  invitation: AcceptInvitationDtoInput;
};


export type MutationAddUserToRoleArgs = {
  addUserToRole: UserRoleDtoInput;
};


export type MutationCreateAccessCredentialsRuleArgs = {
  accessCredentialsRule: CreateAccessCredentialsRuleDtoInput;
};


export type MutationCreateCompanyArgs = {
  company: CreateCompanyDtoInput;
};


export type MutationCreateIntegrationProfileArgs = {
  integrationProfile: CreateIntegrationProfileDtoInput;
};


export type MutationCreateInvitationArgs = {
  invitation: CreateInvitationDtoInput;
};


export type MutationCreateProjectArgs = {
  project: CreateProjectDtoInput;
};


export type MutationDeleteAccessCredentialsRuleArgs = {
  accessCredentialsRuleId: Scalars['UUID']['input'];
};


export type MutationDeleteInvitationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserFromRoleArgs = {
  deleteUserFromRole: UserRoleDtoInput;
};


export type MutationGenerateNewIntegrationProfilePasswordArgs = {
  serviceUserId: Scalars['ID']['input'];
};


export type MutationGenerateWebhookCredentialsArgs = {
  integrationProfileId: Scalars['UUID']['input'];
};


export type MutationSetIntegrationProfileStateArgs = {
  integrationProfileId: Scalars['UUID']['input'];
  state: Scalars['Boolean']['input'];
};


export type MutationUpdateAccessCredentialsRuleArgs = {
  accessCredentialsRule: UpdateAccessCredentialsRuleDtoInput;
};


export type MutationUpdateCompanyArgs = {
  company: UpdateCompanyDtoInput;
};


export type MutationUpdateIntegrationProfileArgs = {
  integrationProfile: UpdateIntegrationProfileDtoInput;
};


export type MutationUpdateProjectArgs = {
  project: UpdateProjectDtoInput;
};


export type MutationUpdateUserArgs = {
  user: UserDtoInput;
};


export type MutationVerifyUploadedLogoArgs = {
  companyId: Scalars['UUID']['input'];
  uploadedBlobName: Scalars['String']['input'];
};

export enum PinCodeApplicability {
  Generate = 'GENERATE',
  HandledByPartnerNeedToRequestPartnerFor = 'HANDLED_BY_PARTNER_NEED_TO_REQUEST_PARTNER_FOR',
  SpecifiedByPartnerInCreateUpdateRequests = 'SPECIFIED_BY_PARTNER_IN_CREATE_UPDATE_REQUESTS'
}

export type ProjectDto = {
  __typename?: 'ProjectDto';
  companyContact: Scalars['String']['output'];
  defaultLanguage: Language;
  fallbackLanguage: Language;
  id: Scalars['UUID']['output'];
  integrationProfiles: Array<IntegrationProfileDto>;
  jiraIssueTypeKey?: Maybe<Scalars['String']['output']>;
  jiraProjectKey?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ownerCompanyName?: Maybe<Scalars['String']['output']>;
  ownerSubjectId: Scalars['UUID']['output'];
  providerCompanyName?: Maybe<Scalars['String']['output']>;
  providerSubjectId: Scalars['UUID']['output'];
};

export type Query = {
  __typename?: 'Query';
  accessCredentialRuleDetail?: Maybe<AccessCredentialsRuleDto>;
  accessCredentialRuleList?: Maybe<Array<Maybe<AccessCredentialsRuleDto>>>;
  companies: CompanyTableItemDtoPaginatedListDto;
  company: CompanyDto;
  integrationProfile: IntegrationProfileDto;
  integrationProfiles: Array<IntegrationProfileDto>;
  invitationStatus: InvitationStatusDto;
  invitations: InvitationTableItemDtoPaginatedListDto;
  logoUploadLink: CompanyLogoUploadLinkDto;
  project: ProjectDto;
  projects: Array<Maybe<ProjectDto>>;
  roles: Array<Maybe<RoleDto>>;
  user: UserDto;
  users: UserDtoPaginatedListDto;
};


export type QueryAccessCredentialRuleDetailArgs = {
  ruleId: Scalars['UUID']['input'];
};


export type QueryAccessCredentialRuleListArgs = {
  integrationProfileId: Scalars['UUID']['input'];
};


export type QueryCompaniesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  pageIndex?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  subjectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryCompanyArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  subjectId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryIntegrationProfileArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryIntegrationProfilesArgs = {
  areActive?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['UUID']['input']>;
  serviceAccountUserId?: InputMaybe<Scalars['UUID']['input']>;
  serviceType?: InputMaybe<ServiceType>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryInvitationStatusArgs = {
  email: Scalars['String']['input'];
  invitationId: Scalars['UUID']['input'];
};


export type QueryInvitationsArgs = {
  companyId: Scalars['ID']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  searchKey?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLogoUploadLinkArgs = {
  companyId: Scalars['UUID']['input'];
  fileExtension: Scalars['String']['input'];
};


export type QueryProjectArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryProjectsArgs = {
  subjectIds: Array<Scalars['UUID']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  userIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type RoleDto = {
  __typename?: 'RoleDto';
  id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum ServiceType {
  BagToBox = 'BAG_TO_BOX',
  Delivery = 'DELIVERY',
  HandToHand = 'HAND_TO_HAND'
}

export enum UniquenessKind {
  PersonIdentifier = 'PERSON_IDENTIFIER',
  PinCode = 'PIN_CODE'
}

export type UpdateAccessCredentialsRuleDtoInput = {
  accessCredentialActions: Array<AccessCredentialAction>;
  charactersRange?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  pinCodeApplicability?: InputMaybe<PinCodeApplicability>;
  pinCodeLength?: InputMaybe<Scalars['Int']['input']>;
  requireInstallationId: Scalars['Boolean']['input'];
  requirePersonalIdentifier: Scalars['Boolean']['input'];
  requirePhoneNumber: Scalars['Boolean']['input'];
  requirePinCode: Scalars['Boolean']['input'];
  requirePointId: Scalars['Boolean']['input'];
  requireToSpecifyValidFrom: Scalars['Boolean']['input'];
  requireToSpecifyValidTo: Scalars['Boolean']['input'];
  uniqueCharacters?: InputMaybe<Scalars['String']['input']>;
  uniquenessKind: UniquenessKind;
};

export type UpdateCompanyDtoInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  dic?: InputMaybe<Scalars['String']['input']>;
  ico?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type UpdateIntegrationProfileDtoInput = {
  handlingCustomersNotifyingOnBehalfOfPartner: Scalars['Boolean']['input'];
  id: Scalars['UUID']['input'];
  maxPeriod: Scalars['String']['input'];
  maxUnanchoredBookingPercentageRatio: Scalars['Int']['input'];
  paymentAppName?: InputMaybe<Scalars['String']['input']>;
  paymentUrl?: InputMaybe<Scalars['String']['input']>;
  requireWhitelistVerification: Scalars['Boolean']['input'];
  webHookUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectDtoInput = {
  companyContact: Scalars['String']['input'];
  defaultLanguage: Language;
  fallbackLanguage: Language;
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type UserDto = {
  __typename?: 'UserDto';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  roleIds?: Maybe<Array<Scalars['UUID']['output']>>;
  roles: Array<RoleDto>;
  subjectIds?: Maybe<Array<Scalars['UUID']['output']>>;
  userType: UserType;
};

export type UserDtoInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  subjectIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  userType: UserType;
};

export type UserDtoPaginatedListDto = {
  __typename?: 'UserDtoPaginatedListDto';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  items?: Maybe<Array<UserDto>>;
  pageIndex: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type UserRoleDtoInput = {
  roleId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
};

export enum UserType {
  Registered = 'REGISTERED',
  ServiceAccount = 'SERVICE_ACCOUNT'
}

export enum WebhookApiVersion {
  V1 = 'V1'
}

export type WebhookCredentialDto = {
  __typename?: 'WebhookCredentialDto';
  password: Scalars['String']['output'];
  userName: Scalars['UUID']['output'];
};

export type CompanyDetailFragment = { __typename?: 'CompanyDto', name: string, id: string, contactPersonId: string, logoUrl?: string | null, type: CompanyType, isProvider: boolean, logoUrlUpdatedAt?: string | null, ico?: string | null, dic?: string | null, address: { __typename?: 'AddressDto', city: string, country: string, postalCode: string, street: string }, projects: Array<{ __typename?: 'CompanyProjectDto', isOwner: boolean, isProvider: boolean, isTenant: boolean, projectId: string, projectName?: string | null }> };

export type CompaniesListItemFragment = { __typename?: 'CompanyTableItemDto', id: string, name: string, subjectId: string };

export type UserRoleFragment = { __typename?: 'RoleDto', id: string, name?: string | null };

export type UserFragment = { __typename?: 'UserDto', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, subjectIds?: Array<string> | null, userType: UserType, roles: Array<{ __typename?: 'RoleDto', id: string, name?: string | null }> };

export type InvitationStatusQueryVariables = Exact<{
  invitationId: Scalars['UUID']['input'];
  email: Scalars['String']['input'];
}>;


export type InvitationStatusQuery = { __typename?: 'Query', invitationStatus: { __typename?: 'InvitationStatusDto', status: InvitationStatus, verificationEmailText?: string | null } };

export type AcceptInvitationMutationVariables = Exact<{
  userEmail: Scalars['String']['input'];
  invitationId: Scalars['UUID']['input'];
}>;


export type AcceptInvitationMutation = { __typename?: 'Mutation', acceptInvitation: { __typename?: 'AcceptedInvitationDto', identityToken: string } };

export type AddUserToRoleMutationVariables = Exact<{
  roleId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
}>;


export type AddUserToRoleMutation = { __typename?: 'Mutation', addUserToRole: string };

export type CreateCompanyMutationVariables = Exact<{
  name: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  street: Scalars['String']['input'];
  type: CompanyType;
  ico?: InputMaybe<Scalars['String']['input']>;
  dic?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'CompanyDto', id: string, name: string, contactPersonId: string, ico?: string | null, dic?: string | null, address: { __typename?: 'AddressDto', city: string, country: string, postalCode: string, street: string } } };

export type CreateInvitationMutationVariables = Exact<{
  companyId: Scalars['UUID']['input'];
  userEmail: Scalars['String']['input'];
}>;


export type CreateInvitationMutation = { __typename?: 'Mutation', createInvitation: { __typename?: 'InvitationDto', id: string } };

export type CreateProjectMutationVariables = Exact<{
  project: CreateProjectDtoInput;
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', createProject: { __typename?: 'ProjectDto', id: string } };

export type DeleteInvitationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteInvitationMutation = { __typename?: 'Mutation', deleteInvitation: boolean };

export type DeleteUserFromRoleMutationVariables = Exact<{
  roleId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
}>;


export type DeleteUserFromRoleMutation = { __typename?: 'Mutation', deleteUserFromRole: string };

export type GenerateNewIntegrationProfilePasswordMutationVariables = Exact<{
  serviceUserId: Scalars['ID']['input'];
}>;


export type GenerateNewIntegrationProfilePasswordMutation = { __typename?: 'Mutation', generateNewIntegrationProfilePassword: string };

export type GenerateWebhookCredentialsMutationVariables = Exact<{
  integrationProfileId: Scalars['UUID']['input'];
}>;


export type GenerateWebhookCredentialsMutation = { __typename?: 'Mutation', generateWebhookCredentials: { __typename?: 'WebhookCredentialDto', userName: string, password: string } };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  street: Scalars['String']['input'];
  ico?: InputMaybe<Scalars['String']['input']>;
  dic?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename?: 'CompanyDto', id: string, name: string, ico?: string | null, dic?: string | null, address: { __typename?: 'AddressDto', city: string, country: string, postalCode: string, street: string } } };

export type UpdateProjectMutationVariables = Exact<{
  project: UpdateProjectDtoInput;
}>;


export type UpdateProjectMutation = { __typename?: 'Mutation', updateProject: { __typename?: 'ProjectDto', id: string, name: string, companyContact: string } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  userType: UserType;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserDto', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, userType: UserType } };

export type VerifyUploadedLogoMutationVariables = Exact<{
  companyId: Scalars['UUID']['input'];
  uploadedBlobName: Scalars['String']['input'];
}>;


export type VerifyUploadedLogoMutation = { __typename?: 'Mutation', verifyUploadedLogo: boolean };

export type CompaniesQueryVariables = Exact<{
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type CompaniesQuery = { __typename?: 'Query', companies: { __typename?: 'CompanyTableItemDtoPaginatedListDto', pageIndex: number, hasNextPage: boolean, hasPreviousPage: boolean, totalCount: number, totalPages: number, items: Array<{ __typename?: 'CompanyTableItemDto', id: string, name: string, subjectId: string }> } };

export type CompanyRolesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CompanyRolesQuery = { __typename?: 'Query', company: { __typename?: 'CompanyDto', roles: Array<{ __typename?: 'RoleDto', id: string, name?: string | null }> } };

export type CompanyUsersQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  orderBy?: InputMaybe<Scalars['String']['input']>;
}>;


export type CompanyUsersQuery = { __typename?: 'Query', company: { __typename?: 'CompanyDto', users: { __typename?: 'UserDtoPaginatedListDto', pageIndex: number, totalPages: number, totalCount: number, hasPreviousPage: boolean, hasNextPage: boolean, items?: Array<{ __typename?: 'UserDto', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, subjectIds?: Array<string> | null, userType: UserType, roles: Array<{ __typename?: 'RoleDto', id: string, name?: string | null }> }> | null } } };

export type CompanyQueryVariables = Exact<{
  subjectId: Scalars['ID']['input'];
}>;


export type CompanyQuery = { __typename?: 'Query', company: { __typename?: 'CompanyDto', name: string, id: string, contactPersonId: string, logoUrl?: string | null, type: CompanyType, isProvider: boolean, logoUrlUpdatedAt?: string | null, ico?: string | null, dic?: string | null, address: { __typename?: 'AddressDto', city: string, country: string, postalCode: string, street: string }, projects: Array<{ __typename?: 'CompanyProjectDto', isOwner: boolean, isProvider: boolean, isTenant: boolean, projectId: string, projectName?: string | null }> } };

export type InvitationsQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  searchKey?: InputMaybe<Scalars['String']['input']>;
}>;


export type InvitationsQuery = { __typename?: 'Query', invitations: { __typename?: 'InvitationTableItemDtoPaginatedListDto', pageIndex: number, totalPages: number, totalCount: number, hasPreviousPage: boolean, hasNextPage: boolean, items: Array<{ __typename?: 'InvitationTableItemDto', id: string, userEmail: string, expiration: string, isAccepted: boolean }> } };

export type LogoUploadLinkQueryVariables = Exact<{
  companyId: Scalars['UUID']['input'];
  fileExtension: Scalars['String']['input'];
}>;


export type LogoUploadLinkQuery = { __typename?: 'Query', logoUploadLink: { __typename?: 'CompanyLogoUploadLinkDto', uploadImageUrl: string, uploadedBlobName: string } };

export type ProjectQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type ProjectQuery = { __typename?: 'Query', project: { __typename?: 'ProjectDto', id: string, name: string, companyContact: string, defaultLanguage: Language, fallbackLanguage: Language, ownerCompanyName?: string | null, ownerSubjectId: string, providerCompanyName?: string | null, providerSubjectId: string } };

export type ProjectsQueryVariables = Exact<{
  subjectIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;


export type ProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'ProjectDto', id: string, name: string, ownerCompanyName?: string | null, ownerSubjectId: string, providerCompanyName?: string | null, providerSubjectId: string } | null> };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'RoleDto', id: string, name?: string | null } | null> };

export type UserRolesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserRolesQuery = { __typename?: 'Query', user: { __typename?: 'UserDto', roles: Array<{ __typename?: 'RoleDto', id: string, name?: string | null }> } };

export type UserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'UserDto', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, subjectIds?: Array<string> | null, userType: UserType, roles: Array<{ __typename?: 'RoleDto', id: string, name?: string | null }> } };


export const CompanyDetailFragmentDoc = `
    fragment CompanyDetail on CompanyDto {
  name
  id
  contactPersonId
  logoUrl
  type
  isProvider
  logoUrlUpdatedAt
  ico
  dic
  address {
    city
    country
    postalCode
    street
  }
  projects {
    isOwner
    isProvider
    isTenant
    projectId
    projectName
  }
}
    `;
export const CompaniesListItemFragmentDoc = `
    fragment CompaniesListItem on CompanyTableItemDto {
  id
  name
  subjectId
}
    `;
export const UserRoleFragmentDoc = `
    fragment UserRole on RoleDto {
  id
  name
}
    `;
export const UserFragmentDoc = `
    fragment User on UserDto {
  id
  email
  firstName
  lastName
  phoneNumber
  subjectIds
  userType
  roles {
    ...UserRole
  }
}
    ${UserRoleFragmentDoc}`;
export const InvitationStatusDocument = `
    query InvitationStatus($invitationId: UUID!, $email: String!) {
  invitationStatus(invitationId: $invitationId, email: $email) {
    status
    verificationEmailText
  }
}
    `;

export const useInvitationStatusQuery = <
      TData = InvitationStatusQuery,
      TError = unknown
    >(
      variables: InvitationStatusQueryVariables,
      options?: Omit<UseQueryOptions<InvitationStatusQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<InvitationStatusQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<InvitationStatusQuery, TError, TData>(
      {
    queryKey: ['InvitationStatus', variables],
    queryFn: fetcher<InvitationStatusQuery, InvitationStatusQueryVariables>(InvitationStatusDocument, variables),
    ...options
  }
    )};

export const AcceptInvitationDocument = `
    mutation AcceptInvitation($userEmail: String!, $invitationId: UUID!) {
  acceptInvitation(
    invitation: {userEmail: $userEmail, invitationId: $invitationId}
  ) {
    identityToken
  }
}
    `;

export const useAcceptInvitationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AcceptInvitationMutation, TError, AcceptInvitationMutationVariables, TContext>) => {
    
    return useMutation<AcceptInvitationMutation, TError, AcceptInvitationMutationVariables, TContext>(
      {
    mutationKey: ['AcceptInvitation'],
    mutationFn: (variables?: AcceptInvitationMutationVariables) => fetcher<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, variables)(),
    ...options
  }
    )};

export const AddUserToRoleDocument = `
    mutation AddUserToRole($roleId: UUID!, $userId: UUID!) {
  addUserToRole(addUserToRole: {roleId: $roleId, userId: $userId})
}
    `;

export const useAddUserToRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddUserToRoleMutation, TError, AddUserToRoleMutationVariables, TContext>) => {
    
    return useMutation<AddUserToRoleMutation, TError, AddUserToRoleMutationVariables, TContext>(
      {
    mutationKey: ['AddUserToRole'],
    mutationFn: (variables?: AddUserToRoleMutationVariables) => fetcher<AddUserToRoleMutation, AddUserToRoleMutationVariables>(AddUserToRoleDocument, variables)(),
    ...options
  }
    )};

export const CreateCompanyDocument = `
    mutation CreateCompany($name: String!, $city: String!, $country: String!, $postalCode: String!, $street: String!, $type: CompanyType!, $ico: String, $dic: String) {
  createCompany(
    company: {name: $name, city: $city, country: $country, postalCode: $postalCode, street: $street, type: $type, ico: $ico, dic: $dic}
  ) {
    id
    name
    contactPersonId
    address {
      city
      country
      postalCode
      street
    }
    ico
    dic
  }
}
    `;

export const useCreateCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCompanyMutation, TError, CreateCompanyMutationVariables, TContext>) => {
    
    return useMutation<CreateCompanyMutation, TError, CreateCompanyMutationVariables, TContext>(
      {
    mutationKey: ['CreateCompany'],
    mutationFn: (variables?: CreateCompanyMutationVariables) => fetcher<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, variables)(),
    ...options
  }
    )};

export const CreateInvitationDocument = `
    mutation CreateInvitation($companyId: UUID!, $userEmail: String!) {
  createInvitation(invitation: {companyId: $companyId, userEmail: $userEmail}) {
    id
  }
}
    `;

export const useCreateInvitationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateInvitationMutation, TError, CreateInvitationMutationVariables, TContext>) => {
    
    return useMutation<CreateInvitationMutation, TError, CreateInvitationMutationVariables, TContext>(
      {
    mutationKey: ['CreateInvitation'],
    mutationFn: (variables?: CreateInvitationMutationVariables) => fetcher<CreateInvitationMutation, CreateInvitationMutationVariables>(CreateInvitationDocument, variables)(),
    ...options
  }
    )};

export const CreateProjectDocument = `
    mutation CreateProject($project: CreateProjectDtoInput!) {
  createProject(project: $project) {
    id
  }
}
    `;

export const useCreateProjectMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateProjectMutation, TError, CreateProjectMutationVariables, TContext>) => {
    
    return useMutation<CreateProjectMutation, TError, CreateProjectMutationVariables, TContext>(
      {
    mutationKey: ['CreateProject'],
    mutationFn: (variables?: CreateProjectMutationVariables) => fetcher<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, variables)(),
    ...options
  }
    )};

export const DeleteInvitationDocument = `
    mutation DeleteInvitation($id: ID!) {
  deleteInvitation(id: $id)
}
    `;

export const useDeleteInvitationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteInvitationMutation, TError, DeleteInvitationMutationVariables, TContext>) => {
    
    return useMutation<DeleteInvitationMutation, TError, DeleteInvitationMutationVariables, TContext>(
      {
    mutationKey: ['DeleteInvitation'],
    mutationFn: (variables?: DeleteInvitationMutationVariables) => fetcher<DeleteInvitationMutation, DeleteInvitationMutationVariables>(DeleteInvitationDocument, variables)(),
    ...options
  }
    )};

export const DeleteUserFromRoleDocument = `
    mutation DeleteUserFromRole($roleId: UUID!, $userId: UUID!) {
  deleteUserFromRole(deleteUserFromRole: {roleId: $roleId, userId: $userId})
}
    `;

export const useDeleteUserFromRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteUserFromRoleMutation, TError, DeleteUserFromRoleMutationVariables, TContext>) => {
    
    return useMutation<DeleteUserFromRoleMutation, TError, DeleteUserFromRoleMutationVariables, TContext>(
      {
    mutationKey: ['DeleteUserFromRole'],
    mutationFn: (variables?: DeleteUserFromRoleMutationVariables) => fetcher<DeleteUserFromRoleMutation, DeleteUserFromRoleMutationVariables>(DeleteUserFromRoleDocument, variables)(),
    ...options
  }
    )};

export const GenerateNewIntegrationProfilePasswordDocument = `
    mutation GenerateNewIntegrationProfilePassword($serviceUserId: ID!) {
  generateNewIntegrationProfilePassword(serviceUserId: $serviceUserId)
}
    `;

export const useGenerateNewIntegrationProfilePasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GenerateNewIntegrationProfilePasswordMutation, TError, GenerateNewIntegrationProfilePasswordMutationVariables, TContext>) => {
    
    return useMutation<GenerateNewIntegrationProfilePasswordMutation, TError, GenerateNewIntegrationProfilePasswordMutationVariables, TContext>(
      {
    mutationKey: ['GenerateNewIntegrationProfilePassword'],
    mutationFn: (variables?: GenerateNewIntegrationProfilePasswordMutationVariables) => fetcher<GenerateNewIntegrationProfilePasswordMutation, GenerateNewIntegrationProfilePasswordMutationVariables>(GenerateNewIntegrationProfilePasswordDocument, variables)(),
    ...options
  }
    )};

export const GenerateWebhookCredentialsDocument = `
    mutation GenerateWebhookCredentials($integrationProfileId: UUID!) {
  generateWebhookCredentials(integrationProfileId: $integrationProfileId) {
    userName
    password
  }
}
    `;

export const useGenerateWebhookCredentialsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GenerateWebhookCredentialsMutation, TError, GenerateWebhookCredentialsMutationVariables, TContext>) => {
    
    return useMutation<GenerateWebhookCredentialsMutation, TError, GenerateWebhookCredentialsMutationVariables, TContext>(
      {
    mutationKey: ['GenerateWebhookCredentials'],
    mutationFn: (variables?: GenerateWebhookCredentialsMutationVariables) => fetcher<GenerateWebhookCredentialsMutation, GenerateWebhookCredentialsMutationVariables>(GenerateWebhookCredentialsDocument, variables)(),
    ...options
  }
    )};

export const UpdateCompanyDocument = `
    mutation UpdateCompany($id: UUID!, $name: String!, $city: String!, $country: String!, $postalCode: String!, $street: String!, $ico: String, $dic: String) {
  updateCompany(
    company: {id: $id, name: $name, city: $city, country: $country, postalCode: $postalCode, street: $street, ico: $ico, dic: $dic}
  ) {
    id
    name
    address {
      city
      country
      postalCode
      street
    }
    ico
    dic
  }
}
    `;

export const useUpdateCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCompanyMutation, TError, UpdateCompanyMutationVariables, TContext>) => {
    
    return useMutation<UpdateCompanyMutation, TError, UpdateCompanyMutationVariables, TContext>(
      {
    mutationKey: ['UpdateCompany'],
    mutationFn: (variables?: UpdateCompanyMutationVariables) => fetcher<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, variables)(),
    ...options
  }
    )};

export const UpdateProjectDocument = `
    mutation UpdateProject($project: UpdateProjectDtoInput!) {
  updateProject(project: $project) {
    id
    name
    companyContact
  }
}
    `;

export const useUpdateProjectMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateProjectMutation, TError, UpdateProjectMutationVariables, TContext>) => {
    
    return useMutation<UpdateProjectMutation, TError, UpdateProjectMutationVariables, TContext>(
      {
    mutationKey: ['UpdateProject'],
    mutationFn: (variables?: UpdateProjectMutationVariables) => fetcher<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, variables)(),
    ...options
  }
    )};

export const UpdateUserDocument = `
    mutation UpdateUser($id: UUID!, $email: String!, $firstName: String!, $lastName: String!, $phoneNumber: String!, $userType: UserType!) {
  updateUser(
    user: {id: $id, email: $email, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, userType: $userType}
  ) {
    id
    email
    firstName
    lastName
    phoneNumber
    userType
  }
}
    `;

export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>) => {
    
    return useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      {
    mutationKey: ['UpdateUser'],
    mutationFn: (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables)(),
    ...options
  }
    )};

export const VerifyUploadedLogoDocument = `
    mutation VerifyUploadedLogo($companyId: UUID!, $uploadedBlobName: String!) {
  verifyUploadedLogo(companyId: $companyId, uploadedBlobName: $uploadedBlobName)
}
    `;

export const useVerifyUploadedLogoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<VerifyUploadedLogoMutation, TError, VerifyUploadedLogoMutationVariables, TContext>) => {
    
    return useMutation<VerifyUploadedLogoMutation, TError, VerifyUploadedLogoMutationVariables, TContext>(
      {
    mutationKey: ['VerifyUploadedLogo'],
    mutationFn: (variables?: VerifyUploadedLogoMutationVariables) => fetcher<VerifyUploadedLogoMutation, VerifyUploadedLogoMutationVariables>(VerifyUploadedLogoDocument, variables)(),
    ...options
  }
    )};

export const CompaniesDocument = `
    query Companies($pageIndex: Int!, $pageSize: Int!, $name: String) {
  companies(pageIndex: $pageIndex, pageSize: $pageSize, name: $name) {
    items {
      ...CompaniesListItem
    }
    pageIndex
    hasNextPage
    hasPreviousPage
    totalCount
    totalPages
  }
}
    ${CompaniesListItemFragmentDoc}`;

export const useCompaniesQuery = <
      TData = CompaniesQuery,
      TError = unknown
    >(
      variables: CompaniesQueryVariables,
      options?: Omit<UseQueryOptions<CompaniesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CompaniesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CompaniesQuery, TError, TData>(
      {
    queryKey: ['Companies', variables],
    queryFn: fetcher<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, variables),
    ...options
  }
    )};

export const CompanyRolesDocument = `
    query CompanyRoles($id: ID!) {
  company(companyId: $id) {
    roles {
      id
      name
    }
  }
}
    `;

export const useCompanyRolesQuery = <
      TData = CompanyRolesQuery,
      TError = unknown
    >(
      variables: CompanyRolesQueryVariables,
      options?: Omit<UseQueryOptions<CompanyRolesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CompanyRolesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CompanyRolesQuery, TError, TData>(
      {
    queryKey: ['CompanyRoles', variables],
    queryFn: fetcher<CompanyRolesQuery, CompanyRolesQueryVariables>(CompanyRolesDocument, variables),
    ...options
  }
    )};

export const CompanyUsersDocument = `
    query CompanyUsers($companyId: ID!, $pageIndex: Int!, $pageSize: Int!, $orderBy: String) {
  company(companyId: $companyId) {
    users(pageSize: $pageSize, pageIndex: $pageIndex, orderBy: $orderBy) {
      items {
        ...User
      }
      pageIndex
      totalPages
      totalCount
      hasPreviousPage
      hasNextPage
    }
  }
}
    ${UserFragmentDoc}`;

export const useCompanyUsersQuery = <
      TData = CompanyUsersQuery,
      TError = unknown
    >(
      variables: CompanyUsersQueryVariables,
      options?: Omit<UseQueryOptions<CompanyUsersQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CompanyUsersQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CompanyUsersQuery, TError, TData>(
      {
    queryKey: ['CompanyUsers', variables],
    queryFn: fetcher<CompanyUsersQuery, CompanyUsersQueryVariables>(CompanyUsersDocument, variables),
    ...options
  }
    )};

export const CompanyDocument = `
    query Company($subjectId: ID!) {
  company(subjectId: $subjectId) {
    ...CompanyDetail
  }
}
    ${CompanyDetailFragmentDoc}`;

export const useCompanyQuery = <
      TData = CompanyQuery,
      TError = unknown
    >(
      variables: CompanyQueryVariables,
      options?: Omit<UseQueryOptions<CompanyQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CompanyQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CompanyQuery, TError, TData>(
      {
    queryKey: ['Company', variables],
    queryFn: fetcher<CompanyQuery, CompanyQueryVariables>(CompanyDocument, variables),
    ...options
  }
    )};

export const InvitationsDocument = `
    query Invitations($companyId: ID!, $pageIndex: Int!, $pageSize: Int!, $searchKey: String) {
  invitations(
    companyId: $companyId
    pageIndex: $pageIndex
    pageSize: $pageSize
    searchKey: $searchKey
  ) {
    items {
      id
      userEmail
      expiration
      isAccepted
    }
    pageIndex
    totalPages
    totalCount
    hasPreviousPage
    hasNextPage
  }
}
    `;

export const useInvitationsQuery = <
      TData = InvitationsQuery,
      TError = unknown
    >(
      variables: InvitationsQueryVariables,
      options?: Omit<UseQueryOptions<InvitationsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<InvitationsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<InvitationsQuery, TError, TData>(
      {
    queryKey: ['Invitations', variables],
    queryFn: fetcher<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, variables),
    ...options
  }
    )};

export const LogoUploadLinkDocument = `
    query LogoUploadLink($companyId: UUID!, $fileExtension: String!) {
  logoUploadLink(companyId: $companyId, fileExtension: $fileExtension) {
    uploadImageUrl
    uploadedBlobName
  }
}
    `;

export const useLogoUploadLinkQuery = <
      TData = LogoUploadLinkQuery,
      TError = unknown
    >(
      variables: LogoUploadLinkQueryVariables,
      options?: Omit<UseQueryOptions<LogoUploadLinkQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<LogoUploadLinkQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<LogoUploadLinkQuery, TError, TData>(
      {
    queryKey: ['LogoUploadLink', variables],
    queryFn: fetcher<LogoUploadLinkQuery, LogoUploadLinkQueryVariables>(LogoUploadLinkDocument, variables),
    ...options
  }
    )};

export const ProjectDocument = `
    query Project($id: UUID!) {
  project(id: $id) {
    id
    name
    companyContact
    defaultLanguage
    fallbackLanguage
    ownerCompanyName
    ownerSubjectId
    providerCompanyName
    providerSubjectId
  }
}
    `;

export const useProjectQuery = <
      TData = ProjectQuery,
      TError = unknown
    >(
      variables: ProjectQueryVariables,
      options?: Omit<UseQueryOptions<ProjectQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ProjectQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ProjectQuery, TError, TData>(
      {
    queryKey: ['Project', variables],
    queryFn: fetcher<ProjectQuery, ProjectQueryVariables>(ProjectDocument, variables),
    ...options
  }
    )};

export const ProjectsDocument = `
    query Projects($subjectIds: [UUID!]!) {
  projects(subjectIds: $subjectIds) {
    id
    name
    ownerCompanyName
    ownerSubjectId
    providerCompanyName
    providerSubjectId
  }
}
    `;

export const useProjectsQuery = <
      TData = ProjectsQuery,
      TError = unknown
    >(
      variables: ProjectsQueryVariables,
      options?: Omit<UseQueryOptions<ProjectsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ProjectsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ProjectsQuery, TError, TData>(
      {
    queryKey: ['Projects', variables],
    queryFn: fetcher<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, variables),
    ...options
  }
    )};

export const RolesDocument = `
    query Roles {
  roles {
    id
    name
  }
}
    `;

export const useRolesQuery = <
      TData = RolesQuery,
      TError = unknown
    >(
      variables?: RolesQueryVariables,
      options?: Omit<UseQueryOptions<RolesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<RolesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<RolesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['Roles'] : ['Roles', variables],
    queryFn: fetcher<RolesQuery, RolesQueryVariables>(RolesDocument, variables),
    ...options
  }
    )};

export const UserRolesDocument = `
    query UserRoles($id: ID!) {
  user(id: $id) {
    roles {
      id
      name
    }
  }
}
    `;

export const useUserRolesQuery = <
      TData = UserRolesQuery,
      TError = unknown
    >(
      variables: UserRolesQueryVariables,
      options?: Omit<UseQueryOptions<UserRolesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserRolesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserRolesQuery, TError, TData>(
      {
    queryKey: ['UserRoles', variables],
    queryFn: fetcher<UserRolesQuery, UserRolesQueryVariables>(UserRolesDocument, variables),
    ...options
  }
    )};

export const UserDocument = `
    query User($id: ID!) {
  user(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export const useUserQuery = <
      TData = UserQuery,
      TError = unknown
    >(
      variables: UserQueryVariables,
      options?: Omit<UseQueryOptions<UserQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserQuery, TError, TData>(
      {
    queryKey: ['User', variables],
    queryFn: fetcher<UserQuery, UserQueryVariables>(UserDocument, variables),
    ...options
  }
    )};
